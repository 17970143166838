import React from 'react';
import Header from '../../components/pteportal/Header';
import Footer from '../../components/pteportal/Footer';
import ClientFeedback from '../../components/pteportal/ClientFeedback';

import { Link} from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

import ReactPixel from 'react-facebook-pixel';
import API, {base} from "api.js";
import { setAlert} from 'action/index.js';
import {store,persistor} from 'store/index';
import { Redirect } from "react-router-dom";
import {
	Modal,
	ModalBody,
	ModalFooter
} from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';

class Home extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            indexModal:false,
            name:"",
            phone:"",
            email:"",
            center:"",
            subdomain:false,
            process:false
        }
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
		let subdomain = window.location.hostname.split('.').shift();
		if (subdomain !== 'www') {
			this.setState({subdomain:true});
		}
		if((store.getState().token)?false:true){
		  	setTimeout(() => { this.setState({indexModal:true}) }, 10000);
		}
		ReactGA.initialize('UA-157984080-1');
		ReactGA.pageview(window.location.pathname + window.location.search);
		ReactPixel.init('197333187452630');
		ReactPixel.pageView();
    }

    closeIndexModal = () =>{
        this.setState({indexModal:false});
    }

    changeInput = (e) => {
        let { name, value } = e.target;
        this.setState({ [name] : value });
    };

    postSubmit = async(e) => {

        if (!this.validator.allValid()) {
			this.validator.showMessages();
			this.forceUpdate();
			return false;
        }
        e.preventDefault();
        this.setState({process:true});
        var bodyFormData = new FormData();
        bodyFormData.append('phone', this.state.phone);
        bodyFormData.append('name', this.state.name);
        bodyFormData.append('email', this.state.email);
        bodyFormData.append('center', this.state.center);
        let res = null;
        try {
			res = await API({
				method: 'POST',
				url: '/submit-query',
				data: bodyFormData
			});
        } catch(error) {
            this.setState({process:false });
            return "l";
        };
        console.log(res);

        if (res.status === 200) {
          	store.dispatch(setAlert({'flag':true,'type':1,'message':res.data.response.message,process:false}));
          	this.setState({indexModal:false});
        } else if (res.response.status === 422) {
          	this.setState({error:res.response,process:false });
          	let str = '';
          	Object.values(this.state.error.data.errors).forEach((val) => { str += val[0]+'\n'; })
          	this.setState({error_bag:str});
        }
  	}


	render() {
	    if(this.state.subdomain){
	    	return (<Redirect to={`auth/login`}/>);
	    }

    	return (
    		<React.Fragment>
    			<Helmet htmlAttributes>
					<title>20task, Empowering your Institute</title>
					<meta name="twitter:title" content="20task, Empowering your Institute" />
					<meta property="og:title" content="20task, Empowering your Institute" />
					<meta name="description" content="20 Task, being the top NAATI / PTE training institute in Parramatta, work with an aim of providing quality education to students at affordable prices and help them achieve their dream of permanent residency in Australia. To join the best PTE and NAATI coaching classes, contact us at info@20task.com" />

					<meta name="twitter:description" content="20 Task, being the top NAATI / PTE training institute in Parramatta, work with an aim of providing quality education to students at affordable prices and help them achieve their dream of permanent residency in Australia. To join the best PTE and NAATI coaching classes, contact us at info@20task.com" />
					<meta property="og:description" content="20 Task, being the top NAATI / PTE training institute in Parramatta, work with an aim of providing quality education to students at affordable prices and help them achieve their dream of permanent residency in Australia. To join the best PTE and NAATI coaching classes, contact us at info@20task.com" />
      			</Helmet>

				<section class="header-with-banner">
					<Header active="home" />
				</section>


				<main id="main-content">
					<div class="space-after-nav"></div>
					<div id="myCarousel" class="carousel slide abf-section" data-bs-ride="carousel">
						<div class="carousel-indicators">
							<button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
							<button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
							<button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
							<button type="button" data-bs-target="#myCarousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
							<button type="button" data-bs-target="#myCarousel" data-bs-slide-to="4" aria-label="Slide 5"></button>
						</div>
      					<div class="carousel-inner">
							<div class="carousel-item carousel-item0 active" data-ride="carousel" data-interval="500">
							   	<img class="d-block w-100" src="/images/20task/images/20task-banner-01.jpg" alt="Ace PTE with 20Task!" />
							  	<div class="container">
							    	<div class="carousel-caption text-start">
								      	<h1>Ace PTE with 20Task!</h1>
								      	<p>AI-powered Portal built on PTE Algorithms</p>
								      	<p><a class="red-button btn btn-lg btn-primary" href="/contact">Try Our Software for Your Institute</a></p>
							    	</div>
								</div>
							</div>
					        <div class="carousel-item carousel-item1">
								<div class="container">
									<div class="carousel-caption text-start">
										<h1>AI-Powered Portal <br></br>Prepares Students for Success</h1>
										<p>PTE-like algorithms replicate PTE 95%</p>
										<p><a class="red-button btn btn-lg btn-primary" href="/contact">Try Our Software for Your Institute</a></p>
									</div>
								</div>
					        </div>
							<div class="carousel-item carousel-item2">
								<div class="container">
							    	<div class="carousel-caption text-start">
								      	<h1>Gain Confidence Attempting<br></br>Sectional Tests or Mock Tests</h1>
								      	<p>Questions curated from previous PTE exam.</p>
								      	<p><a class="red-button btn btn-lg btn-primary" href="/contact">Try Our Software for Your Institute</a></p>
							    	</div>
							  	</div>
							</div>
					        <div class="carousel-item carousel-item3">
					          	<div class="container">
					            	<div class="carousel-caption text-start">
					              		<h1>Get Instant Scores with<br></br>AI-Powered Portal</h1>
					              		<p>Game up your PTE Training with an<br></br>AI-powered test portal</p>
					              		<p><a class="red-button btn btn-lg btn-primary" href="/contact">Try Our Software for Your Institute</a></p>
					            	</div>
					          	</div>
					        </div>
					        <div class="carousel-item carousel-item4">
					          	<div class="container">
					            	<div class="carousel-caption text-start">
						              	<h1>Overcome Weakness with Mock Tests</h1>
						              	<p>Once a student pass mock tests,<br></br>they definitely ace PTE!</p>
						              	<p><a class="red-button btn btn-lg btn-primary" href="/contact">Try Our Software for Your Institute</a></p>
					            	</div>
					          	</div>
					        </div>
      					</div>
    				</div>
				    <section class="features-section py-80 display-n" id="features-section">
						<div class="container section-container-bg-bubble">
							<div class="sec-title text-center">
							  	<h2 class="title-h2">Features That Help Your Students Succeed</h2>
							  	<hr class="title-divider divider-blue"></hr>
							  	<p class="sec-sub-title">AI-powered Portal has <strong>advanced test and assessment features</strong> that eliminate the need for trainers to invest their time and efficiency in operational test-taking tasks like creating question papers, checking them and giving feedback to students. The Portal does all the tasks that a trainer has to do for evaluating a student and giving them an analysis.</p>
							  	<p class="sec-sub-title">You can add multiple students and make their profiles. You can track their progress whether they take the test at home or institute. You can allow them full or partial access to the sections based on the pricing package they subscribe to.</p>
							  	<p class="sec-sub-title">With 20task.com, you can <strong>innovate PTE training, saving time in grading</strong>. The time can instead be invested in improving the teaching material and approach. 20task.com helps trainers do what they do best – TRAINING! And lets your institute have better results, better trainer efficiency and better profits.</p>
							</div>
							<div class="row">
							  	<div class="col-md-4">
								    <div class="twtask-flip-card">
								      	<div class="twtask-flip-card-inner">
								        	<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/real-time-test-environment.png" width="100" height="100" alt="Real-time test environment" />
								          		<h2>Real-time test environment</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>The Portal is built on similar standards as the PTE and has all the features of a real test. Students get comfortable with the test pattern as it is 95% the same as the real test. </p>
								        	</div>
								      	</div>
								    </div>
							  	</div>
							  	<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
							          			<img src="/images/20task/images//detailed-analytics.png" width="100" height="100"  alt="Detailed Analytics" />
							          			<h2>Detailed Analytics</h2>
							        		</div>
							        		<div class="twtask-flip-card-back">
							          			<p>A dashboard keeps the record of all the attempted questions, and you can check detailed performance analysis. The algorithms replicate PTE. Whatever score students achieve in mocks, they’ll definitely get just 5% more or less in the real exam.</p>
							        		</div>
							      		</div>
							    	</div>
							  	</div>
							  	<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
							          			<img src="/images/20task/images//instant-and-accurate-evaluation.png" width="100" height="100"  alt="Instant and Accurate Evaluation" class="twtask-flip-card-front-icon" />
							          			<h2>Instant and Accurate Evaluation</h2>
							        		</div>
						        			<div class="twtask-flip-card-back">
							          			<p>AI algorithm analysis within just 1-2 seconds helps the student know what they could have done to score better. They don’t get just the score but inputs as well explaining the areas of improvement.</p>
							        		</div>
							      		</div>
							    	</div>
							  	</div>
							  	<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
							          			<img src="/images/20task/images//mock-tests.png" width="100" height="100"  alt="Mock Tests" class="twtask-flip-card-front-icon" />
							          			<h2>Mock Tests</h2>
							        		</div>
							        		<div class="twtask-flip-card-back">
							          			<p>Appearing in mock tests or individual sections help students understand their mistakes and improve. The algorithm of 20task.com is upto 95% same as the PTE. So, you can rest assured that if your students are doing well in mocks, they’ll surely do good at PTE.</p>
							        		</div>
							      		</div>
							    	</div>
							  	</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
							          			<img src="/images/20task/images//in-depth-feedback.png" width="100" height="100"  alt="In-depth Feedback" class="twtask-flip-card-front-icon" />
							          			<h2>In-depth Feedback</h2>
							        		</div>
							        		<div class="twtask-flip-card-back">
							          			<p>Each response is evaluated individually. Corrections are shown for each question, so students learn quickly. Getting both mistakes and possible ways of corrections help students learn from their mistakes, reduce repetitive mistakes and improve their overall score.</p>
							        		</div>
							      		</div>
							    	</div>
							  	</div>
							  	<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images//templates.png" width="100" height="100"  alt="Templates" class="twtask-flip-card-front-icon" />
								          		<h2>Templates</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>Students learn some sections quickly by revising the previous questions and answers curated in the templates. Templates are like shortcut learning formulas. In some sections, they don’t lose valuable time practising when they can quickly learn and ace the exam.</p>
								        	</div>
								      	</div>
								    </div>
								</div>
							</div>
						</div>
					</section>
    				<section class="about-us-sec py-80">
				      	<div class="container">
					        <div class="sec-title text-center">
								<h2 class="title-h2">How 20task.com Helps Your Student Perfect Their Score?</h2>
								<hr class="title-divider divider-blue"></hr>
								<p class="sec-sub-title">Every PTE aspirant has some goal in their mind. <strong>20task.com is a curation of several questions by the leading experts.</strong> You get a brilliant UI where each section is divided strategically, so students quickly find what they are looking for. The Portal Is designed to <strong>power your students’ preparation with new-age Artificial Intelligence</strong>. Built on PTE standard algorithms. The technology is quick. Within just a few seconds, evaluates your students’ response and gives the corrections, imitating a human-like response, but only quicker and more accurately. The 95% algorithm adoption from the PTE exam ensures that your student gets just 5% more or less marks and score almost as expected.</p>
								<p class="sec-sub-title"><strong>20task.com pumps up your students’ confidence, and they get closer to achieving their desired score.</strong></p>
					        </div>
					        <div class="row tabRow">
								<div class="col-md-12 tabRowcol tabLinkRowcol">
									<div class="ed_tab">
										<div class="row">
											<div class="col-md-3"><button class="ed_tablinks mb-3 p-3 shadow active" id="edDefaultOpen" href="tab1">Full Length Scored Mock Test</button></div>
											<div class="col-md-3"><button class="ed_tablinks mb-3 p-3 shadow"  href="tab2">Section-wise PTE Practice Test</button></div>
											<div class="col-md-3"><button class="ed_tablinks mb-3 p-3 shadow"  href="tab3">Sample Answers</button></div>
											<div class="col-md-3"><button class="ed_tablinks mb-3 p-3 shadow"  href="tab4">Detailed Analysis</button></div>
										</div>
									</div>
								</div>
					          	<div class="col-md-12 tabRowcol tabContentRowcol">
					            	<div class="row">
					              		<div class="col-md-12"></div>
					              		<div class="col-md-12">
					                		<div id="tab1" class="container ed_tabcontent tab-pane fade shadow rounded bg-white show active p-0">
							                  	<h3 class="firsth3">PTE full length Scored Mock Test</h3>
							                  	<img src="/images/20task/images/dash_img_01.png" alt="Application Screen" class="ed_tabcontent_image" loading="lazy" />
					                		</div>

					                		<div id="tab2" class="container ed_tabcontent tab-pane fade shadow rounded bg-white show p-0">
					                  			<h3>Section-wise PTE Practice Test</h3>
					                  			<img src="/images/20task/images/Section-Wise-PTE-Practice-Test.jpg" alt="Application Screen" class="ed_tabcontent_image" loading="lazy" />
					                		</div>

					                		<div id="tab3" class="container ed_tabcontent tab-pane fade shadow rounded bg-white show p-0">
					                  			<h3>Sample Answers</h3>
					                  			<img src="/images/20task/images/Sample-Answers-20task.png" alt="Application Screen" class="ed_tabcontent_image" loading="lazy" />
					                		</div>

					                		<div id="tab4" class="container ed_tabcontent tab-pane fade shadow rounded bg-white show p-0">
					                  			<h3>Detailed Analysis</h3>
					                  			<img src="/images/20task/images/Detailed-Analysis-20task.png" alt="Application Screen" class="ed_tabcontent_image" loading="lazy" />
					                		</div>
					              		</div>
					              		<div class="col-md-12"></div>
				            		</div>
					            	<div class="row cta-row">
					              		<div class="col-md-12 text-center">
					                		<a href="#" class="blue-button">Crack PTE with 20task.com</a>
					              		</div>
					            	</div>
					          	</div>
				        	</div>
			      		</div>
			    	</section>
				    <section class="process-sec py-80">
				      	<div class="container section-container-bg-bubble">
					        <div class="sec-title text-center">
					          	<h2 class="title-h2">Know about the PTE Academic Exam Pattern</h2>
					          	<hr class="title-divider divider-blue"></hr>
					          	<p class="sec-sub-title">20task.com provides the PTE aspirants the opportunity to get minute details about the PTE test before the exam day. Practicing with pte full mock test with answers are more of the process of tuning oneself before the PTE academic exam. PTE practice test helps to rate your performance and get the scorecard instantly. PTE test consists of four sections having individual question type covering in it. All the exam stimulation is added in our online PTE mock test and PTE practice test to give you an edge while preparing for the PTE test.</p>
					        </div>
					        <div class="row">
					          	<div class="col-lg-6">
					            	<div class="process-box box_process_top">
					              		<div class="icon text-center">
					                		<img src="/images/20task/images/20task-icon3-07-06-21.png" width="67" height="67" alt="Reading" loading="lazy"/>
					              		</div>
					              		<div class="text text-center">
							                <h3 class="title title-h3">Reading</h3>
							                <p class="description">This section assesses reading and comprehension ability. Information is given in English. Students have various types of questions like fill-in blanks, multiple-choice questions, paragraph reordering, and others.</p>
							                <a href="#" class="link_btn process-link-btn" >Improve Reading <span class="process-arrow">&#10148;</span></a>
					              		</div>
					            	</div>
					          	</div>
					          	<div class="col-lg-6">
					            	<div class="process-box">
					              		<div class="icon text-center">
					                		<img src="/images/20task/images/20taskicon1-07-06-21.png" width="67" height="67" alt="Speaking" loading="lazy"/>
					              		</div>
					              		<div class="text text-center">
							                <h3 class="title title-h3">Speaking</h3>
							                <p class="description">Students need a good quality microphone for this section. They have to Read Aloud, Repeat Sentences, Describe Images, Re-tell Lectures and Answer Short Questions. Their pronunciation and diction are tested.</p>
							                <a href="#" class="link_btn process-link-btn" >Improve Speaking <span class="process-arrow">&#10148;</span></a>
					              		</div>
					            	</div>
					          	</div>
					          	<div class="col-lg-6">
					            	<div class="process-box box_process_top">
					              		<div class="icon text-center">
					                		<img src="/images/20task/images/20-task-icon2-07-06-21.png" width="67" height="67" alt="Writing" loading="lazy"/>
					              		</div>
					              		<div class="text text-center">
							                <h3 class="title title-h3">Writing</h3>
							                <p class="description">In this section, students are given questions like summarising texts and writing an essay. They can use text editing functions such as cut, copy and paste to save some time. They need to maintain good speed and avoid typos.</p>
							                <a href="#" class="link_btn process-link-btn" >Improve Writing <span class="process-arrow">&#10148;</span></a>
					              		</div>
					            	</div>
					          	</div>
					          	<div class="col-lg-6">
					            	<div class="process-box">
					              		<div class="icon text-center">
					                		<img src="/images/20task/images/20taskicon4-07-06-21.png" width="67" height="67" alt="Listening" loading="lazy"/>
					              		</div>
					              		<div class="text text-center">
							                <h3 class="title title-h3">Listening</h3>
							                <p class="description">This section tests your students’ hearing and understanding abilities. Audio Clips with English dialogues or monologues are played. They have to answer the corresponding questions correctly by selecting from the given options.</p>
							                <a href="#" class="link_btn process-link-btn" > Improve Listening <span class="process-arrow">&#10148;</span></a>
					              		</div>
					            	</div>
					          	</div>
				        	</div>
				      	</div>
				    </section>
				    <section class="testimonial-section" id="testimonialSection">
				      	<div class="container">
				        	<div class="sec-title text-center">
				          		<h2 class="title-h2">Results That Applaud Us</h2>
				          		<hr class="title-divider divider-blue"></hr>
				        	</div>
				      	</div>
				    </section>
    				<div class="gtco-testimonials">
      					<div class="owl-carousel owl-carousel1 owl-theme">
					        <div>
					          	<div class="card text-center">
						            <div class="card-body">
						              	<p class="card-text"><span class="twtask-left-quote">“</span>An amazing portal that has taken the stress out of looking after every student. I can easily know their scores from the dashboard.<span class="twtask-right-quote">”</span></p>
						            </div>
					          	</div>
					        </div>
					        <div>
					          	<div class="card text-center">
						            <div class="card-body">
						              	<p class="card-text"><span class="twtask-left-quote">“</span>The portal is efficient, smooth, simple to use and shows verified results.<span class="twtask-right-quote">”</span> </p>
						            </div>
					          	</div>
					        </div>
					        <div>
					          	<div class="card text-center">
						            <div class="card-body">
						              	<p class="card-text"><span class="twtask-left-quote">“</span>I am glad I found this PTE Portal. Using this, it feels like I am running an online institute as the software provides study material, conducts mock tests and does everything for PTE preparation.<span class="twtask-right-quote">”</span> </p>
						            </div>
					          	</div>
					        </div>
      					</div>
    				</div>
				    {/*<section class="faq-section py-80">
				      	<div class="container section-container-bg-bubble">
					        <div class="sec-title text-center">
					          	<h2 class="title-h2">Frequently Asked Questions</h2>
					          	<hr class="title-divider divider-blue"></hr>
					          	<p class="sec-sub-title">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
					        </div>
				        	<div class="row">
				          		<div class="accordion" id="accordionExample">
						            <div class="accordion-item">
						              	<h2 class="accordion-header" id="headingOne">
							                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
							                  Question #1
							                </button>
						              	</h2>
						              	<div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
							                <div class="accordion-body">
							                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry.
							                </div>
						              	</div>
						            </div>
				            		<div class="accordion-item">
						              	<h2 class="accordion-header" id="headingTwo">
							                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
							                  Question #2
							                </button>
						              	</h2>
						              	<div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
							                <div class="accordion-body">
							                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry.
							                </div>
						              	</div>
				            		</div>
				            		<div class="accordion-item">
						              	<h2 class="accordion-header" id="headingThree">
							                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
							                  Question #3
							                </button>
						              	</h2>
						              	<div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
							                <div class="accordion-body">
							                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
							                </div>
						              	</div>
				            		</div>
				          		</div>
				        	</div>
				      	</div>
			    	</section>*/}
					<section class="faq-section py-80">
						<div class="container section-container-bg-bubble">
							<div class="sec-title text-center">
								<h2 class="title-h2">What Our Institutes say about us?</h2>
								<hr class="title-divider divider-blue"></hr>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-4"></div>
							<div className="col-lg-6">
								<div className="box_process_top">
									<ul className="text">
										<li><p className="description"><b>Trusted software for students for achieving their dream with the endless support from 20 Task.</b></p></li>
										<li><p className="description"><b>Friendly User Interface for Trainers and Students.</b></p></li>
										<li><p className="description"><b>Wide variety of Question Bank for fulfilling the Best Scores.</b></p></li>
										<li><p className="description"><b>The Exam Quality and Scoring is Topnotch for the students who wants to achieve Best Results.</b></p></li>
									</ul>
								</div>
							</div>
							<div className="col-lg-2"></div>
						</div>
					</section>

					<section class="features-section py-80 display-n" id="features-section">
						<div class="container section-container-bg-bubble">
							<div class="sec-title text-center">
							  	<h2 class="title-h2">Some of Our Esteemed Partners</h2>
							  	<hr class="title-divider divider-blue"></hr>
							</div>
							<div class="row">
							  	<div class="col-md-4">
								    <div class="twtask-flip-card">
								      	<div class="twtask-flip-card-inner">
								        	<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner1.jpg" width="100" height="100" alt="FTI" />
								          		<h2>FTI</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>Financial Training Institute</p>
								        	</div>
								      	</div>
								    </div>
							  	</div>
							  	<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
							          			<img src="/images/20task/images/partners/partner2.png" width="100" height="100"  alt="the dawn" />
							          			<h2>The Dawn</h2>
							        		</div>
							        		<div class="twtask-flip-card-back">
							          			<p>Education & Immigration Consultant</p>
							        		</div>
							      		</div>
							    	</div>
							  	</div>
							  	<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
							          			<img src="/images/20task/images/partners/partner3.jpg" width="100" height="100"  alt="TELL PEC" class="twtask-flip-card-front-icon" />
							          			<h2>TELL PEC</h2>
							        		</div>
						        			<div class="twtask-flip-card-back">
							          			<p>Foreign Education & Immigration Consultant</p>
							        		</div>
							      		</div>
							    	</div>
							  	</div>
							  	<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
							          			<img src="/images/20task/images/partners/partner4.jpeg" width="100" height="100"  alt="Homes Pte" class="twtask-flip-card-front-icon" />
							          			<h2>Homes PTE</h2>
							        		</div>
							        		<div class="twtask-flip-card-back">
							          			<p>Centre for English Language</p>
							        		</div>
							      		</div>
							    	</div>
							  	</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
							          			<img src="/images/20task/images/partners/partner5.png" width="100" height="100"  alt="TILICHO" class="twtask-flip-card-front-icon" />
							          			<h2>TILICHO Consulting Group</h2>
							        		</div>
							        		<div class="twtask-flip-card-back">
							          			<p>Education-Migration-Career</p>
							        		</div>
							      		</div>
							    	</div>
							  	</div>
							  	<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner6.jpeg" width="100" height="100"  alt="BBC" class="twtask-flip-card-front-icon" />
								          		<h2>BBC</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>BIG BEN Consultancy</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner7.jpeg" width="100" height="100"  alt="Shri RAM" class="twtask-flip-card-front-icon" />
								          		<h2>Shri RAM Mentors</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>Shri RAM Mentors</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner8.jpeg" width="100" height="100"  alt="KIC" class="twtask-flip-card-front-icon" />
								          		<h2>K.I.C.</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>Kalsana Immigration Consultancy</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner9.jpeg" width="100" height="100"  alt="GTO" class="twtask-flip-card-front-icon" />
								          		<h2>GTO</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>Global Touch Overseas</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner10.jpeg" width="100" height="100"  alt="CC" class="twtask-flip-card-front-icon" />
								          		<h2>CC</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>CC</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner11.jpeg" width="100" height="100"  alt="PIML" class="twtask-flip-card-front-icon" />
								          		<h2>PIML</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>PIML Education & Immigration Services</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner12.jpeg" width="100" height="100"  alt="Storm Overseas" class="twtask-flip-card-front-icon" />
								          		<h2>Storm Overseas</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>Storm Overseas North Gujrat</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner13.jpeg" width="100" height="100"  alt="SEDO" class="twtask-flip-card-front-icon" />
								          		<h2>SEDO</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>Skill Education</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner14.jpeg" width="100" height="100"  alt="Sir dp" class="twtask-flip-card-front-icon" />
								          		<h2>SIRDP</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>SIRDP</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner15.jpeg" width="100" height="100"  alt="Success Door" class="twtask-flip-card-front-icon" />
								          		<h2>Success Door</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>Success Door</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner16.jpg" width="100" height="100"  alt="EC" class="twtask-flip-card-front-icon" />
								          		<h2>EIFEL CAMPUS</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>Eifel Campus Immigration & Education Services</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner17.jpg" width="100" height="100"  alt="MD Overseas" class="twtask-flip-card-front-icon" />
								          		<h2>MD Overseas</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>MD Overseas</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner18.jpeg" width="100" height="100"  alt="Diamond Path" class="twtask-flip-card-front-icon" />
								          		<h2>Diamond Path Immigration</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>Diamond Path Immigration</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner19.jpg" width="100" height="100"  alt="The Right Track" class="twtask-flip-card-front-icon" />
								          		<h2>The Right Track</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>The Right Track - Education & Overseas Services</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner21.jpg" width="100" height="100"  alt="GH Immigration" class="twtask-flip-card-front-icon" />
								          		<h2>GH Immigration</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>GH Immigration - IELTS | SUDY VISA | PTE</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner22.jpeg" width="100" height="100"  alt="i9 Global Links" class="twtask-flip-card-front-icon" />
								          		<h2>i9 Global Links</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>i9 Global Links - Education & Immigration</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner23.jpeg" width="100" height="100"  alt="Preeti Rana" class="twtask-flip-card-front-icon" />
								          		<h2>Preeti Rana</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>Diamond Path Immigration</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner24.jpg" width="100" height="100"  alt="Blue Ocean" class="twtask-flip-card-front-icon" />
								          		<h2>Blue Ocean</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>Blue Ocean Visas Pvt. Ltd.</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner25.jpg" width="100" height="100"  alt="Dream Fly" class="twtask-flip-card-front-icon" />
								          		<h2>Dream Fly</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>Dream Fly</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner26.jpeg" width="100" height="100"  alt="Jhajj" class="twtask-flip-card-front-icon" />
								          		<h2>Jhajj Immigration</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>Jhajj Immigration</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner27.jpeg" width="100" height="100"  alt="E School" class="twtask-flip-card-front-icon" />
								          		<h2>E School</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>E School</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner28.jpeg" width="100" height="100"  alt="Daffodils" class="twtask-flip-card-front-icon" />
								          		<h2>Daffodils</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>Daffodils The Educity</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner29.jpg" width="100" height="100"  alt="Smart Study Australia" class="twtask-flip-card-front-icon" />
								          		<h2>Smart Study Australia</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>Smart Study Australia</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner30.jpg" width="100" height="100"  alt="Smart Study Australia" class="twtask-flip-card-front-icon" />
								          		<h2>Education Lane</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>Education Lane</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner31.jpg" width="100" height="100"  alt="Smart Study Australia" class="twtask-flip-card-front-icon" />
								          		<h2>The Global</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>The Global - Study Abroad | Migration | Academy</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner32.jpg" width="100" height="100"  alt="Smart Study Australia" class="twtask-flip-card-front-icon" />
								          		<h2>Sky Immigration</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>Sky Immigration</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner33.jpg" width="100" height="100"  alt="Smart Study Australia" class="twtask-flip-card-front-icon" />
								          		<h2>Dunya Consultants Pvt. Ltd.</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>Dunya Consultants Pvt. Ltd.</p>
								        	</div>
								      	</div>
								    </div>
								</div>
								<div class="col-md-4">
							    	<div class="twtask-flip-card">
							      		<div class="twtask-flip-card-inner">
							        		<div class="twtask-flip-card-front">
								          		<img src="/images/20task/images/partners/partner34.jpg" width="100" height="100"  alt="Smart Study Australia" class="twtask-flip-card-front-icon" />
								          		<h2>Successvisa</h2>
								        	</div>
								        	<div class="twtask-flip-card-back">
								          		<p>Successvisa - Your Success Is Our Business</p>
								        	</div>
								      	</div>
								    </div>
								</div>

							</div>
						</div>
					</section>

					<section class="horizontalCta py-80">
				      	<div class="container">
					        <div class="row">
					          	<div class="col-md-12 text-center horizontalCta-heading">
						            <h2>Take Scored PTE Mock Test for Free</h2>
						            <a href="#" class="red-button btn">Sign Up for FREE!</a>
					          	</div>
					        </div>
				      	</div>
				    </section>
  				</main>
  				<Footer />
			</React.Fragment>
		);
    }
}

export default Home;
