import React, { Component } from "react";

// reactstrap components
import {
  Col,
  Progress,
  Button,
  Input
} from "reactstrap";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Dictaphone from "./Dictaphone.jsx";
import Sound from 'react-sound';
import { s3base } from "api.js";
import getBlobDuration from 'get-blob-duration';
import MicRecorder from 'mic-recorder-to-mp3';
import NoMicrophone from "views/NoMicrophone.jsx";
import "components/custom.css";
import StayConnected from 'components/ConnectedRequest/StayConnected';

let Mp3Recorder = new MicRecorder({ bitRate: 128 });

class RetellLec extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      question:"",
      secondCount:8,
      duration:0,
      secondCount2:10,
      recordCount:0,
      recordId:1,
      intervalId:0,
      intervalId2:0,
      questionId:"",
      reset:false,
      soundStatus:true,
      soundPer:0,
      trans:'',
      volume:100,
      correctFlag:true,
      beepStatus:false,
      position: 0,
      file:''
    };
  }
  componentDidMount() {
    if (!this.props.answer) {
  	 var intervalId = setInterval(this.timer, 1000);
	   this.setState({intervalId: intervalId});
  	}
    navigator.getUserMedia({ audio: true },
      () => {
        console.log('Permission Granted');
        this.setState({ isBlocked: false });
      },
      () => {
        console.log('Permission Denied');
        this.setState({ isBlocked: true })
      },
    );
  }

  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
        clearInterval(state.intervalId);
        clearInterval(state.intervalId2);
        clearInterval(state.recordId);
  			
        return {correctFlag:true,recordCount:0,soundPer:0,isRecording:false,secondCount:8,secondCount2:10,questionId: props.data.id,blobURL:'',reset:true,trans:'',soundStatus:true,intervalId2:0,duration:0};
  		}else{
  			if (!state.questionId) {
  				return {correctFlag:true,questionId: props.data.id};
  			}
  		}	
  	}
  }

  start = () => {
    this.setState({ beepStatus:true});
  };

  handleBeepFinishedPlaying = () =>{
    if (this.state.isBlocked) {
      console.log('Permission Denied');
    } else {
      SpeechRecognition.startListening({ continuous: true, language: 'en-GB' });
      Mp3Recorder = new MicRecorder({ bitRate: 128 });
      Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true,blobURL:'',beepStatus:false});
        }).catch((e) => console.error(e));
    }
  }

  getDuration = async (blob) => {
    let duration = 0;
    duration = await getBlobDuration(blob).then((d) => {
      return d;
    }).catch(async (e) => {
      return await getBlobDuration(URL.createObjectURL(blob));
    });
    return duration;
  }

  getFile = (blob, buffer) => {
    let file = null;
    try {
      file = new File([blob], 'noname2.mp3', { type: blob.type });
    } catch (err) {
      file = new File(buffer, 'noname2.mp3', {
        type: blob.type,
        lastModified: Date.now()
      });
    }
    return file;
  }

  getBlobURL = (blob, file) => {
    let url = null;
    try {
      url = URL.createObjectURL(file);
    } catch (err) {
      url = URL.createObjectURL(blob);
    }
    return url;
  }

  stop = async () => {
    if (!this.state.isBlocked && this.state.isRecording) {
      setTimeout(() => {
        Mp3Recorder
        .stop()
        .getMp3()
        .then(async ([buffer, blob]) => {

          const duration = await this.getDuration(blob);
          const file = this.getFile(blob, buffer);
          const blobURL = this.getBlobURL(blob, file);

          SpeechRecognition.stopListening();

          this.setState({
            blobURL,
            isRecording: false,
            recordCount: 100,
            file: file,
            duration: duration
          }, () => {
            const dataToSend = {
              data: file,
              audio_text: this.state.trans,
              duration: duration
            };
            this.props.submit(dataToSend, true);
            this.props.showResult();
          });
        }).catch((e) => console.log(e));
      }, 100);
    }
  };

  setTrans = (a)=>{
    if (this.state.trans != a) {
      this.setState({trans:a})  
    }
  }

  stopTest = async () => {
    if (!this.state.isBlocked && this.state.isRecording) {
      setTimeout(() => {
        Mp3Recorder
        .stop()
        .getMp3()
        .then(async ([buffer, blob]) => {

          const duration = await this.getDuration(blob);
          const file = this.getFile(blob, buffer);
          const blobURL = this.getBlobURL(blob, file);

          SpeechRecognition.stopListening();

          this.props.nextQuestion({
            data: file,
            audio_text: this.state.trans,
            duration: duration
          });
        }).catch((e) => console.log(e));
      }, 100);
    } else {
      this.props.nextQuestion({
        data: this.state.file,
        audio_text: this.state.trans,
        duration: this.state.duration
      });
    }
  };

  updateCorrect = () =>{
     this.props.submit({'data':'',audio_text:'',duration:0});
     this.setState({correctFlag:false})
  }

	timer = () => {
    if (this.state.secondCount === 0) {
      clearInterval(this.state.intervalId);
      
    }else{
      this.setState({ secondCount: this.state.secondCount -1 });  
    }
    
  }

  timer1 = () => {
    if (this.state.secondCount2 === 0) {
      clearInterval(this.state.intervalId2);
      this.start();
      var recordId = setInterval(this.timerRecord, 1000);
      this.setState({'soundStatus':false,recordId: recordId});
    }else{
      this.setState({ secondCount2: this.state.secondCount2 -1 });  
    }
    
  }

  handleSongPlaying = (obj) => {
    this.setState({soundPer:obj.position*100/obj.duration});
  }

  handleSongFinishedPlaying = () => {
    this.setState({'soundStatus':false});
    this.props.soundChecked();
  } 

  stopAudio = () => {
    this.setState({'soundStatus':false,secondCount2:20})
  }

	timerRecord = () => {
		if (this.state.recordCount === 40) {
			clearInterval(this.state.recordId);
			this.stop();
		}
		this.setState({ recordCount: this.state.recordCount + 1 });	
	}

  skip = () => {
    clearInterval(this.state.intervalId);
    this.setState({secondCount:0});
  };

  changeRange = (e) => {
	  this.setState({volume: e.target.value});
  }

	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}

  loadingSound = (obj) =>{
    let d = obj.duration/1000 + 20;
    if (obj.duration !== 0 && this.state.intervalId2 ===0){
      var intervalId = setInterval(this.timer1, 1000);
      this.setState({'soundStatus':true,intervalId2: intervalId,secondCount2:Math.ceil(d)})
    }
  } 

  render() {
  	let question ="";

    if (this.props.restart) {
      this.props.restartQue();
    }

    if (this.props.stopRecording) {
      this.stopTest();      
    }

  	if (this.props.data) {
      if (this.state.correctFlag) {
        this.updateCorrect();  
      }
  		question = this.props.data.question;
  	}

  	if (this.state.reset) {
  		var intervalId = setInterval(this.timer, 1000);
		  this.setState({intervalId: intervalId,reset:false});
  	}

    if (this.state.isBlocked) {
      return (<NoMicrophone />);
    }

    return (
      <>
        <StayConnected />
        <Dictaphone setTrans={this.setTrans} status={this.state.reset || this.state.isRecording} />
        
        <Col xs={12}>
        	<h4 className="infoText"></h4>
        </Col>

        <h5 className="unselectable">
          {this.props.data.audio_script}
        </h5>

        <Col xs={12}>
        	<div className="col-lg-12 p-0 text-center">
            {!this.props.resultMode &&
              <div>
                <div className="currentStatus">
                  <div>Current Status:{this.state.soundStatus == true && this.state.secondCount !== 0 && !this.state.isRecording && ' Begining in '+this.state.secondCount+ ' seconds' }
                    {this.state.secondCount == 0 && this.state.blobURL === '' && !this.state.isRecording && ' Playing' }
             	      <br></br>
             	      Volume
                    <Input placeholder="Volume" type="range" min="0" defaultValue={this.state.volume} step="1" onInput={(e) => this.changeRange(e)} max="100"  />
                    <br></br>
                    <Progress color="info" value={(this.state.soundPer)} />
                    {!this.props.testMode && this.state.soundStatus === true && this.state.secondCount == 0  && <div className='stop-btn'><Button color="success" onClick={this.stopAudio} size="sm">Skip</Button></div>}
                    {!this.props.testMode && this.state.secondCount !== 0 && this.state.blobURL === '' && !this.state.isRecording && <div className='stop-btn'><Button color="success" onClick={this.skip} size="sm">Skip</Button></div> }
                  </div>
                </div>
  		          <div className="audio-box">
                  <h4>Recorded Answer</h4>
                  <p><strong>Current status:</strong></p>
                  <p> { this.state.blobURL === '' && this.state.isRecording?"Recording started": "" } </p>
                  <p>  { this.state.secondCount == 0 && this.state.secondCount2 !== 0 && 'Recording starts in '+this.state.secondCount2+ ' seconds'  }</p>

                  <hr></hr>
                  {/*this.state.isRecording && "Recording Time : "+this.state.recordCount*/}
                  {!this.props.testMode && this.state.isRecording && <div className='stop-btn'><Button color="success" onClick={this.stop} size="sm">Stop</Button></div>}
                  <hr></hr>
                  <Progress color="info" value={(this.state.recordCount*2.5)} />
                  <div className="audo-line"><span className="progress-bar-fill-Read intro"></span></div>
                </div>
              </div>
            }

            <Col xs={12}>
              {!this.props.resultMode && this.props.data && this.state.soundStatus && this.state.secondCount === 0 && 
                <Sound
                  url={s3base + this.props.data.media_link}
                  playStatus={Sound.status.PLAYING}
                  onPlaying={this.handleSongPlaying}
                  onLoading={this.loadingSound}
                  onFinishedPlaying={this.handleSongFinishedPlaying}
                  volume={this.state.volume}        
                />
              }
            </Col>
      		  
            <Col xs={12}>
              { this.state.blobURL && !this.props.testMode && 
                <audio src={this.state.blobURL} controls="controls" />
              }
            </Col>
          </div>
        </Col>

        <Col xs={12}>
          <h5>{question}</h5>
        </Col>
      
        {!this.props.resultMode && this.state.beepStatus && 
          <Sound
            url={s3base + "/ptedata/ptemedia/beep.mp3"}
            playStatus={Sound.status.PLAYING}
            onFinishedPlaying={this.handleBeepFinishedPlaying}
          />
        }
      </>
    );
  }
}

export default RetellLec;
