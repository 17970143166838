import React from "react";
import ReactHtmlParser from "react-html-parser";
import { s3base } from "api.js";
import { Button } from "reactstrap";

const Question = ({ mockData, val, que, showScore }) => {
  return (
    <div className="result-div">
      {[2, 4, 5, 15, 19, 14, 16, 18, 17, 13, 20].includes(mockData.type) && (
        <div>
          <h3> Question Audio - </h3>{" "}
          <audio
            src={s3base + val.question.media_link}
            controls="controls"
            controlsList="nodownload"
          />
        </div>
      )}
      {[6, 7, 13].includes(mockData.type) && (
        <div>
          <h3>Your Response</h3>
          <div className="answer-user__response">
            <h5
              style={{
                textAlign: mockData.type == 7 ? "justify" : "center",
              }}
            >
              {ReactHtmlParser(val.answer)}
            </h5>
          </div>
          {[6, 7, 13].includes(mockData.type) && (
            <div>
              <h3>Word count: {val.correct}</h3>
            </div>
          )}
        </div>
      )}
      {[11, 12, 16].includes(mockData.type) && (
        <div>
          {false && <h3>Your Response</h3>}
          {false &&
            val.answer.split(",").map((v, k) => {
              if (
                k > val.correct.length &&
                v.trim() === val.correct.split(",")[k].trim()
              ) {
                return ReactHtmlParser(
                  "<span>" +
                    v +
                    '<span><i class="fa fa-check" style="font-size:32px;color:green"></i></span></span>'
                );
              } else {
                return ReactHtmlParser(
                  "<span>" +
                    v +
                    '<span><i class="fa fa-times" style="font-size:32px;color:red"></i></span></span>'
                );
              }
            })}
        </div>
      )}
      {[19].includes(mockData.type) && (
        <div>
          <h3>Your Response</h3>
          {<span className="result-correct"></span>}
        </div>
      )}
      {[20].includes(mockData.type) && (
        <div>
          <h3>Correct Answer</h3>
          <h5 className="text-success">
            {ReactHtmlParser(val.question.audio_script)}
          </h5>
        </div>
      )}

      {[8, 9, 14, 15, 17, 18, 20].includes(mockData.type) && (
        <div>
          <h3>Your Response</h3>
          <h5>{ReactHtmlParser(val.html)}</h5>
        </div>
      )}
      {[14, 15, 16, 17, 18, 19, 20].includes(mockData.type) && (
        <div>
          <h5 className="text-danger">{val.answer ? "" : "(Not Attempted)"}</h5>
        </div>
      )}
      {[10].includes(mockData.type) && (
        <div className="row">
          <div className="col-md-6">
            <h3>Correct order</h3>
            {que}
          </div>
          <div className="col-md-6">
            <h3>Your Response</h3>
            <div className="col-12">
              <h4 className="infoText"></h4>
            </div>
            <div className="col-12">
              <h5></h5>
            </div>
            <div className="col-12">
              <h3 className="question"></h3>
            </div>
            <div className="col-lg-12">&nbsp;</div>
            <div className="col-lg-12"></div>
            <div className="col-lg-12">&nbsp;</div>
            <ul className="connectList">
              {val.html
                .split("<br />")
                .filter((va) => {
                  if (va !== "") {
                    return va;
                  }
                })
                .map((v) => {
                  return ReactHtmlParser(
                    '<li class="list-group-item1">' + v + "</li>"
                  );
                })}
            </ul>
          </div>
        </div>
      )}
      {[1, 2].includes(mockData.type) && (
        <div>
          <h3>Your Response</h3>
          <h5>
            {val?.file && (
              <audio
                src={s3base + "/ptedata/ptemedia/" + val.file}
                controls="controls"
              />
            )}
          </h5>
          <h5>{val.html !== null ? ReactHtmlParser(val.html) : ""}</h5>
          <div>
            <div className="AIScoreCom__Category-sc-1yzm0aw-12 liAXDW">
              <div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
                <div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJgd"></div>
                Good
              </div>
              <div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
                <div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJbad"></div>{" "}
                Bad pronunciation/Missed words
              </div>
              <div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca"></div>
            </div>
          </div>
        </div>
      )}
      {[3, 4].includes(mockData.type) && (
        <div>
          <h3>Your Response</h3>
          <h5>
            <audio
              src={s3base + "/ptedata/ptemedia/" + val.file}
              controls="controls"
            />
          </h5>
          <h5>{val.html !== null ? ReactHtmlParser(val.html) : ""}</h5>
          <div>
            <div className="AIScoreCom__Category-sc-1yzm0aw-12 liAXDW">
              <div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
                <div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJgd"></div>
                Good
              </div>
              <div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
                <div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJav"></div>
                Average
              </div>
            </div>
          </div>
        </div>
      )}
      {[5].includes(mockData.type) && (
        <div>
          <h3>Your Response</h3>
          <h5>
            <audio
              src={s3base + "/ptedata/ptemedia/" + val.file}
              controls="controls"
            />
          </h5>
          <h5>Your response: {ReactHtmlParser(val.html)}</h5>
          <h5>Correct answer: {val.question.answer}</h5>
        </div>
      )}
      {mockData.questionData && (
        <Button
          color="primary"
          className="viewResultbtn"
          onClick={(e) => showScore(e, mockData.questionData.id)}
          size="sm"
        >
          View Score with Remarks
        </Button>
      )}
    </div>
  );
};

export default Question;
