import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Alert,
  Badge
} from "reactstrap";
import API from "api.js";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

class Prediction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      redirect:false,
      type:0
    };
    this.props.history.push(this.props.match.url);
  }
   componentDidMount() {
    API({
      method: 'GET',
      url: 'prediction/count'
    }).then(data => {
     
      this.setState({'data':data.data.data});
    }).catch(()=>{
      
    });
  }

  showQuestion(type) {
    this.setState({'redirect':true,'type':type});

  }
 
  render() {
    if (this.state.redirect) {
       return <Redirect to={'/admin/questions-weekly/1/'+this.state.type} />;
     }
    return (
      <>
      <Helmet>
        <title>20task, Empowering your Institute.</title>
        </Helmet>
        <div className="content multipleCards">
        <h3>Weekly Prediction Question(s)</h3>
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Speaking Zone</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                   <Col onClick={this.showQuestion.bind(this,1)} xs={3}><Alert color="primary">Read Aloud <Badge pill variant="light">{(1 in this.state.data)?this.state.data[1]:0}</Badge> </Alert></Col>
                   <Col onClick={this.showQuestion.bind(this,2)} xs={3}><Alert color="primary">Repeat Sentence <Badge pill variant="light">{(2 in this.state.data)?this.state.data[2]:0}</Badge> </Alert></Col>
                   <Col onClick={this.showQuestion.bind(this,3)} xs={3}><Alert color="primary">Describe Image <Badge pill variant="light">{(3 in this.state.data)?this.state.data[3]:0}</Badge></Alert></Col>
                   <Col onClick={this.showQuestion.bind(this,4)} xs={3}><Alert color="primary">Respond To Situation <Badge pill variant="light">{(4 in this.state.data)?this.state.data[4]:0}</Badge></Alert></Col>
                   <Col onClick={this.showQuestion.bind(this,5)} xs={3}><Alert color="primary">Answer Short Questions <Badge pill variant="light">{(5 in this.state.data)?this.state.data[5]:0}</Badge></Alert></Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Writting Zone</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                   <Col onClick={this.showQuestion.bind(this,6)} xs={3}><Alert color="warning">Summarize written text<Badge pill variant="light">{(6 in this.state.data)?this.state.data[6]:0}</Badge></Alert></Col>
                   <Col onClick={this.showQuestion.bind(this,7)} xs={3}><Alert color="warning">Write Email <Badge pill variant="light">{(7 in this.state.data)?this.state.data[7]:0}</Badge></Alert></Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Reading</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                   <Col onClick={this.showQuestion.bind(this,8)} xs={3}><Alert color="info">Multiple Type, Single Answer <Badge pill variant="light">{(8 in this.state.data)?this.state.data[8]:0}</Badge> </Alert></Col>
                   <Col onClick={this.showQuestion.bind(this,9)} xs={3}><Alert color="info">Multiple Type, Double Answer <Badge pill variant="light">{(9 in this.state.data)?this.state.data[9]:0}</Badge></Alert></Col>
                   <Col onClick={this.showQuestion.bind(this,10)} xs={3}><Alert color="info">Reorder Paragraph <Badge pill variant="light">{(10 in this.state.data)?this.state.data[10]:0}</Badge></Alert></Col>
                   <Col onClick={this.showQuestion.bind(this,11)} xs={3}><Alert color="info">Reading Fill in the Blanks <Badge pill variant="light">{(11 in this.state.data)?this.state.data[11]:0}</Badge></Alert></Col>
                   <Col onClick={this.showQuestion.bind(this,12)} xs={3}><Alert color="info">Fill in the Blanks Reading & Writing <Badge pill variant="light">{(12 in this.state.data)?this.state.data[12]:0}</Badge> </Alert></Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Listening</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                   <Col onClick={this.showQuestion.bind(this,13)} xs={3}><Alert color="success">Summarize Spoken text <Badge pill variant="light">{(13 in this.state.data)?this.state.data[13]:0}</Badge> </Alert></Col>
                   <Col onClick={this.showQuestion.bind(this,14)} xs={3}><Alert color="success">MCQ Single answer <Badge pill variant="light">{(14 in this.state.data)?this.state.data[14]:0}</Badge> </Alert></Col>
                   <Col onClick={this.showQuestion.bind(this,15)} xs={3}><Alert color="success">MCQ multiple answer <Badge pill variant="light">{(15 in this.state.data)?this.state.data[15]:0}</Badge> </Alert></Col>
                   <Col onClick={this.showQuestion.bind(this,16)} xs={3}><Alert color="success">Listening Fill In the Blanks <Badge pill variant="light">{(16 in this.state.data)?this.state.data[16]:0}</Badge> </Alert></Col>
                   {/* <Col onClick={this.showQuestion.bind(this,17)} xs={3}><Alert color="success">Highlight correct summary <Badge pill variant="light">{(17 in this.state.data)?this.state.data[17]:0}</Badge> </Alert></Col> */}
                   <Col onClick={this.showQuestion.bind(this,18)} xs={3}><Alert color="success">Select Missing Word <Badge pill variant="light">{(18 in this.state.data)?this.state.data[18]:0}</Badge></Alert></Col>
                   <Col onClick={this.showQuestion.bind(this,19)} xs={3}><Alert color="success">Highlight Incorrect word <Badge pill variant="light">{(19 in this.state.data)?this.state.data[19]:0}</Badge></Alert></Col>
                   <Col onClick={this.showQuestion.bind(this,20)} xs={3}><Alert color="success">Write from Dictation <Badge pill variant="light">{(20 in this.state.data)?this.state.data[20]:0}</Badge> </Alert></Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
  }
}

export default Prediction;
