import React, { useEffect, useState } from "react";

const useForm = (initialData) => {
  const [formData, setFormData] = useState(initialData);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const formValid = Object.values(formData).reduce((acc, curr) => {
      if (!curr.isValid) {
        acc = false;
      }
      return acc;
    }, true);

    setIsFormValid(formValid);
  }, [formData]);

  const onChangeHandler = (event) => {
    const { name, value } = event.target;

    const isValid = checkValidity(formData[name], value);

    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: { ...prevFormData[name], value, isValid },
      };
    });
  };

  const checkValidity = (input, value) => {
    return input.validationRule(value);
  };

  const makeFormEmpty = () => {
    setFormData(initialData);
  };

  return { formData, isFormValid, onChangeHandler, makeFormEmpty };
};

export default useForm;
