import React, { useState, useEffect } from 'react';
import {
	Col
} from "reactstrap";
import "components/custom.css";
import reactStringReplace from 'react-string-replace';
import ReactHtmlParser from 'react-html-parser';

export default function ReadingBlank(props) {
	const [question, setQuestion] = useState('');
	const [secondCount, setSecondCount] = useState(1200);
	const [questionId, setQuestionId] = useState('');
	const [reset, setReset] = useState(false);
	const [correctFlag, setCorrectFlag] = useState(true);
	const [resultObj, setResultObj] = useState({});
	const [answerData, setAnswerData] = useState({
		answer: [],
		correct: []
	});
	const [questionPara, setQuestionPara] = useState('');
	const [halfProcessed, setHalfProcessed] = useState('');
	const [quesOptions, setOptions] = useState([]);
	const [optionsRender, setOptionsRender] = useState([]);
	const [correct, setCorrect] = useState([]);

	const [userResponse, setUserResponse] = useState([]);
	const [responseResult, setResponseResult] = useState([]);
	const [dragging, setDragging] = useState('');
	const [draggingIndex, setDraggingIndex] = useState(null);


	/**
	 * Life Cycle. ComponentDidMount()
	 */
	useEffect(() => {
		init();
	}, []);



	/**
	 * Restart Question
	 */
	useEffect(() => {
		if (props.restart === true) {
			init();
		}
	}, [props.restart]);


	/** 
	 * Load new question.
	 * On Next/Previous/Selection Clicked.
	 */
	useEffect(() => {
		if (props.newQuestion === true) {
			init();
			// props.setNewQuestion(); // calling from Timer Component. No need to run from here.
		}
	}, [props.newQuestion]);



	/**
	 * Initialize component behavior
	 */
	const init = () => {
		const question = props.data.question;

		const correct = question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !=0) return i; });
		setCorrect(correct);

		// setQuestionsBody
		const question_body = generateQuestionBody(question);
		setQuestionPara(question_body);

		// options.
		setOptions(optionsOriginalOrder(props.data.option));
	}

	const dragStart = (item, i) => {
		setDragging(item);
		setDraggingIndex(i);
	}

	const allowDrop = (event) => {
		event.preventDefault();
	}

	const dropInQuestion = (data) => {
		const nArr = [...userResponse];
		nArr[data.pos] = dragging;
		setUserResponse(nArr);

		const oArr = [...quesOptions];
		oArr.map((item, i) => {
			item.render_value = nArr.includes(item.original_value) ? '' : item.original_value;
		});
		setOptions(oArr);


		// set userResponse Correct/Wrong
		const compare_with = (data.pos-1)/2;
		const rArr = [...responseResult];
		rArr[data.pos] = dragging === correct[compare_with] ? 1 : 0;
		setResponseResult(rArr);


		// submit
		let submit = {
			answer: nArr,
			correct: correct
		}
		props.submit(submit, true);
		setResultObj(submit);
		setAnswerData(submit);
	}

	const dropInOptions = () => {
		const nArr = [...userResponse];
		nArr[draggingIndex] = '';
		setUserResponse(nArr);

		const oArr = [...quesOptions];
		oArr.map((item, i) => {
			item.render_value = nArr.includes(item.original_value) ? '' : item.original_value;
		});
		setOptions(oArr);
	}


	/**
	 * Options Original Order.
	 */
	const optionsOriginalOrder = (options) => {
		const opts = [];
		options.map((item) => {
			const value = item.options.trim();
			opts.push({
				original_value: value,
				render_value: value
			});			
		});
		return opts;
	}

	/**
	 * Generate Question Body.
	 * called once in didMount hook.
	 */
	const generateQuestionBody = (question) => {
		let lastItemPos = 0;
		reactStringReplace(question.replace(/<[^>]*>?/gm, 'x_x').replace(/x_x(.*?)x_x/g,''), /(__add_blank__)/g, (match, i, o) => (
			lastItemPos = i
		));
		const answerPositions = Array(lastItemPos).fill('');
		setUserResponse(answerPositions);
		setResponseResult(answerPositions);
		setHalfProcessed(question.replace(/<[^>]*>?/gm, 'x_x').replace(/x_x(.*?)x_x/g,''));
	}

	/**
	 * return 
	 */
	return (
		<>
			<Col xs={12}>
				<h4 className="infoText"></h4>
			</Col>

			<Col xs={12}>
				<h5 className="response">{props.data && !props.resultMode && 
					(
						<>
							{
								reactStringReplace(halfProcessed, /(__add_blank__)/g, (match, i, o) => (
									<>
										<span className="span1" 
											draggable={userResponse[i] !== '' ? true : false} 
											onDragStart={() => dragStart(userResponse[i], i)} 
											onDrop={() => dropInQuestion({ pos: i })} 
											onDragOver={allowDrop.bind(allowDrop)}
											no={i} 
											id={'item-'+i}
										>{userResponse[i]}</span>
										{props.answer && 
											<span>
												<span className="checkedSign" style={{display: (props.answer) ? "inline-block" : "none"}} data-id={i} data-text={correct[(i-1)/2]} >
													<i className={responseResult[i] === 1 ? 'fa fa-check' : 'fa fa-times'} style={{ fontSize:'32px', color:`${responseResult[i] === 1 ? 'green' : 'red'}` }}></i>
												</span>
												<span className="correct">{correct[(i-1)/2]}</span>
											</span>
										}
									</>
								))
							}
						</>
					)
				}</h5>
			</Col>

			<Col lg={12} className="anwrsOptions">
				{props.data && !props.resultMode && 
					quesOptions.map((item, i) => {
						return <span 
							draggable={item.render_value !== '' ? true : false} 
							no="0" 
							className="span2" 
							txt={item.render_value} 
							id={`item-${item.render_value}`}
							onDragStart={() => dragStart(item.render_value, i)}
							onDragOver={allowDrop.bind(allowDrop)}
							onDrop={() => dropInOptions()}
						>
							{item.render_value}
						</span>
					})
				}
			</Col>
		</>
	);
}