import React from "react";
import { Button, Table, Modal, ModalBody, ModalFooter } from "reactstrap";
import ReactHtmlParser from "react-html-parser";

const RemarksPopup = ({ modal, mockData, toggle }) => {
  const showHistoryFunc = () => {
    let id = mockData.questionData[mockData.currentQuestion].id;
    let arr = mockData.questionData.map((v) => {
      if (id === v.id) {
        return v.score;
      }
    });
    let arr1 = arr.filter((va) => {
      if (typeof va === "object") {
        return va;
      }
    })[0];
    let a = arr1.map((v) => {
      return `<tr><td>${remarks(v.type)}</td><td>${v.score}/${v.from}</td><td>${
        v.remarks
      }</td></tr>`;
    });
    if (
      [6, 7, 13].includes(mockData.questionData[mockData.currentQuestion].type)
    ) {
      let t_from = 0;
      let t_score = 0;
      for (var i = 0; i <= arr1.length - 1; i++) {
        t_from += arr1[i].from;
        t_score += arr1[i].score;
      }
      a.push(
        '<tr><td>Total</td><td colspan="2">' +
          t_score +
          "/" +
          t_from +
          "</td></tr>"
      );
    }
    return a;
  };

  const remarks = (type) => {
    let str = "";
    switch (type) {
      case 0:
        str = "Content";
        break;
      case 1:
        str = "Fluency";
        break;
      case 2:
        str = "Pronunciation";
        break;
      case 3:
        str = "Score";
        break;
      case 4:
        str = "Grammar";
        break;
      case 5:
        str = "Form";
        break;
      case 6:
        str = "Vocabulary";
        break;
      case 7:
        str = "Spelling";
        break;
      case 8:
        str = mockData.type == 7 ? "Organization" : "Structure";
        break;
      case 9:
        str = mockData.type == 7 ? "Email Conventions" : "Linguistic range";
        break;
      default:
        str = "";
    }
    return str;
  };

  return (
    <Modal isOpen={modal} size="lg">
      <div className="modalHeader">
        <h2></h2>
      </div>
      <ModalBody>
        <Table>
          <tr>
            <th>Component</th>
            <th>Score</th>
            <th>Remarks</th>
          </tr>
          {showHistoryFunc().map((v) => {
            return ReactHtmlParser(v);
          })}
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" className="modalCloseBtn" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RemarksPopup;
