import React from "react";
import ShowHistoryData from "views/ShowHistoryData";
import ReactHtmlParser from "react-html-parser";

const Result = ({
  type,
  resultData,
  historyData,
  resultId,
  answer,
  userResponse,
  questionType,
}) => {
  const resultHtml =
    questionType == 7 ? (
      <>
        {resultData.split("\n").map((item, i) => {
          return <h4>{ReactHtmlParser(item)}</h4>;
        })}
        <h4 className="answer-user__response">{ReactHtmlParser(userResponse)}</h4>
      </>
    ) : (
      resultData.split("\n").map((item, i) => {
        return <h4>{ReactHtmlParser(item)}</h4>;
      })
    );

  return (
    <div className="result-div">
      {resultHtml}
      {(type === "3" || type === "4") && (
        <div>
          <div className="AIScoreCom__Category-sc-1yzm0aw-12 liAXDW">
            <div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
              <div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJgd"></div>
              Good
            </div>
            <div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
              <div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJav"></div>
              Average
            </div>
          </div>
        </div>
      )}

      {(type === "1" || type === "2") && (
        <div>
          <div className="AIScoreCom__Category-sc-1yzm0aw-12 liAXDW">
            <div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
              <div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJgd"></div>
              Good
            </div>
            <div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
              <div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJbad"></div>
              Bad pronunciation/Missed words
            </div>
            <div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca"></div>
          </div>
        </div>
      )}

      {/*<ShowHistory data={this.state.history} flag={1} id={this.state.resultId} />*/}

      <ShowHistoryData
        questionType={questionType}
        data={historyData}
        flag={1}
        id={resultId}
      />
      {type === "5" && (
        <h3>
          Correct Answer - <span className="green-text">{answer}</span>
        </h3>
      )}
    </div>
  );
};

export default Result;
