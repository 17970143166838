import React,{Component} from "react";
import {
  Col,
  Progress,
  Input,
  FormGroup,
  Label
} from "reactstrap";
import "components/custom.css";
import {store,persistor} from 'store/index';
class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question:"",
      secondCount:600,
      questionId:"",
      reset:false,
      content:'',
      lang:store.getState().user.lang,
      resultObj:{},
      correctFlag:true,
      timeoutStat:false
    };
  }
  componentDidMount() {
  	var intervalId = setInterval(this.timer, 1000);
    this.setState({intervalId: intervalId});
  	
  }
  copyToClipboard = () => {
    document.execCommand("copy");
  };

  cutToClipboard = () => {
    document.execCommand("cut");
  };

  pasteToClipboard = async () => {
    alert("Press Ctrl+v for paste text");
 
  };

  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
  			clearInterval(state.intervalId);
  			clearInterval(state.recordId);
  			return {timeoutStat:false,correctFlag:true,secondCount:600,questionId: props.data.id,reset:true,content:''};
  		}else{
  			if (!state.questionId) {
  				return {correctFlag:true,questionId: props.data.id,content:''};
  			}
  		}
  	} 
  }

	timer = () => {
		if (this.state.secondCount === 1) {
			clearInterval(this.state.intervalId);
      this.setState({timeoutStat:true});
		}
		this.setState({ secondCount: this.state.secondCount -1 });
	}

	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}
  chaneText = (e) =>{
    let { name, value } = e.target;
    this.props.showResult();
    this.props.submit({'answer':value,'correct':'','length':value.split(' ').filter(function(n) { return n != '' }).length,'time':this.state.secondCount,lang:this.state.lang},true);
    this.setState({ [name] : value, resultObj: {'answer':value,'correct':'','length':value.split(' ').filter(function(n) { return n != '' }).length,'time':this.state.secondCount,lang:this.state.lang} });
  };
  chaneLang = (e) => {
    this.setState({lang:e.target.value});
  }

  stopTest = () =>{
    this.props.nextQuestion({...this.state.resultObj,time:this.state.secondCount});
  }

  updateCorrect = () =>{
    this.props.submit({'answer':'','correct':'','length':0,time:this.state.secondCount,lang:this.state.lang});
    this.setState({correctFlag:false,resultObj:{'answer':'','correct':'','length':0,time:this.state.secondCount,lang:this.state.lang}});
  }
  
  render() {
  	let question ="";
    if (this.props.restart) {
      this.props.restartQue();
    }
  	if (this.props.data) {
  		question = this.props.data.question;
      if (this.state.correctFlag) {
        this.updateCorrect();  
      }
  	}
  	if (this.state.reset) {
  		var intervalId = setInterval(this.timer, 1000);
		  this.setState({intervalId: intervalId,reset:false});
  	}

    if (this.props.stopRecording) {
      this.stopTest();      
    }
    
    return (
      <>   
      <Col xs={12}>
      	<h4 className="infoText"></h4>
      </Col>
      <Col xs={12}>
      	<div>
          <div>
              <h5>{question}</h5>
            { !this.props.resultMode && !this.props.answer && <>
                    <Col xs={12} className="text-right"><b><p><i class="fa fa-clock-o"></i> <span>Time Left: {Math.floor(this.state.secondCount / 60) +':'+ (this.state.secondCount % 60)}</span></p> </b></Col>
                    <Col xs={2} style={{margin:'10px'}}>
                      
                    </Col>
                        <div>
                          <FormGroup>
                            {!this.state.timeoutStat && <Input type="textarea" oncopy="return false;" autocorrect="false" spellcheck="false" name="content" onChange={(e) => { this.chaneText(e)} } value={this.state.content}  />}
                            {this.state.timeoutStat && <div className="timeout-box">{this.state.content}</div>}
                          </FormGroup>
                          <div className="bottomContent">
                            <div className="copyPaste">
                              <a href="javascript:void(0)" onClick={this.cutToClipboard} className="btn">Cut</a>
                            <a href="javascript:void(0)" onClick={this.copyToClipboard} className="btn">Copy</a>
                            <a href="javascript:void(0)" onClick={this.pasteToClipboard} className="btn">Paste</a>
                            </div>
                            <p className="wordCount">Total word count: {this.state.content.split(' ').filter(function(n) { return n != '' }).length}</p>
                          </div>
                        </div>
                        </>}
          </div>
    		</div>
      </Col>      
      </>
    );
  }
}
export default Summary;